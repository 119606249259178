<!--首页-->
<template>
    <div>
        <div style="padding: 20px 0">
            <!-- 我是首页 -->
            <!--导航-->
            <el-row :gutter="20" style="display: flex;justify-content: space-between;margin: 0 0 20px 0;">
                <el-col :span="8"
                    style="display:flex;padding: 10px;justify-content: space-between;background: #fff;border-radius: 8px;flex: 1;">
                    <div style="display: flex;flex-direction: column;justify-content: space-between;">
                        <div style="font-size: 16px;color: #7E8887;">内部+Vip用户数</div>
                        <div style="font-size: 28px;color: #3C4645;font-weight: bold;">{{ countAll }}</div>
                    </div>
                    <img src="../images/im1.png" alt="" style="width:88px;height: 88px;">
                </el-col>
                <el-col :span="8"
                    style="display:flex;padding: 10px;justify-content: space-between;background: #fff;border-radius: 8px;flex: 1;margin: 0 16px;">
                    <div style="display: flex;flex-direction: column;justify-content: space-between;">
                        <div style="font-size: 16px;color: #7E8887;">会员用户数</div>
                        <div style="font-size: 28px;color: #3C4645;font-weight: bold;">{{ vipCount }}</div>
                    </div>
                    <img src="../images/im2.png" alt="" style="width:88px;height: 88px;">
                </el-col>
                <el-col :span="8"
                    style="display:flex;padding: 10px;justify-content: space-between;background: #fff;border-radius: 8px;flex: 1;margin: 0 16px;">
                    <div style="display: flex;flex-direction: column;justify-content: space-between;">
                        <div style="font-size: 16px;color: #7E8887;">复购人数</div>
                        <div style="display: flex;align-items: baseline;">
                            <div style="font-size: 28px;color: #3C4645;font-weight: bold;margin-right: 10px;">{{ multiplePay }}</div>
                            <div style="font-size: 16px;color: #7E8887;">朵拉<span style="color:#00A78E;">{{multiDuoLaBuy}}</span>人,</div>
                            <div style="font-size: 16px;color: #7E8887;">嘉顿<span style="color:#00A78E;">{{multiJiaDunBuy}}</span>人</div>
                        </div>
                    </div>
                    <img src="../images/im3.png" alt="" style="width:88px;height: 88px;">
                </el-col>
                <el-col :span="8"
                    style="display:flex;padding: 10px;justify-content: space-between;background: #fff;border-radius: 8px;flex: 1;">
                    <div style="display: flex;flex-direction: column;justify-content: space-between;">
                        <div style="font-size: 16px;color: #7E8887;">本月新增人数</div>
                        <div style="font-size: 28px;color: #3C4645;font-weight: bold;">{{ monthAddCount }}</div>
                    </div>
                    <img src="../images/im4.png" alt="" style="width:88px;height: 88px;">
                </el-col>
            </el-row>
            <el-row :gutter="20" style="display: flex;justify-content: space-between;margin: 0 0 20px 0;">
                <el-col :span="8"
                    style="display:flex;padding: 10px;justify-content: space-between;background: #fff;border-radius: 8px;flex: 1;">
                    <div style="display: flex;flex-direction: column;justify-content: space-between;">
                        <div style="font-size: 16px;color: #7E8887;margin-bottom: 10px;">总学习时间(h)</div>
                        <div style="font-size: 24px;color: #3C4645;font-weight: bold;">{{ studyTimeTotal }}</div>
                    </div>
                </el-col>
                <el-col :span="8"
                    style="display:flex;padding: 10px;justify-content: space-between;background: #fff;border-radius: 8px;flex: 1;margin: 0 16px;">
                    <div style="display: flex;flex-direction: column;justify-content: space-between;">
                        <div style="font-size: 16px;color: #7E8887;margin-bottom: 10px;">当月新增会员学习时间(h)</div>
                        <div style="font-size: 24px;color: #3C4645;font-weight: bold;">{{ monthVipStudyTime }}</div>
                    </div>
                </el-col>
                <el-col :span="8"
                    style="display:flex;padding: 10px;justify-content: space-between;background: #fff;border-radius: 8px;flex: 1;margin: 0 16px;">
                    <div style="display: flex;flex-direction: column;justify-content: space-between;">
                        <div style="font-size: 16px;color: #7E8887;margin-bottom: 10px;">学员月平均学习时长(h)</div>
                        <div style="font-size: 24px;color: #3C4645;font-weight: bold;">{{ monthVipStudyTimeAverage }}</div>
                    </div>
                </el-col>
                <el-col :span="8"
                    style="display:flex;padding: 10px;justify-content: space-between;background: #fff;border-radius: 8px;flex: 1;">
                    <div style="display: flex;flex-direction: column;justify-content: space-between;">
                        <div style="font-size: 16px;color: #7E8887;margin-bottom: 10px;">学习5小时以上人数</div>
                        <div style="font-size: 24px;color: #3C4645;font-weight: bold;">{{ countStudy5Hour }}</div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20" style="margin: 16px 0;justify-content: space-between;display: flex;">
                <el-col :span="16" style="width: 67%;padding: 0;">
                    <div id="main1" style="width: 100%;height:518px;background: #fff;border-radius: 8px;"></div>
                </el-col>
                <el-col :span="8"
                    style="display: flex;flex-direction: column;justify-content: space-between;height:518px;width: 33%;padding: 0;margin-left: 16px;"
                    v-if="!agentUserId">
                    <div id="main2" style="width: 100%;height:251px;background: #fff;border-radius: 8px;"></div>
                    <div id="main3" style="width: 100%;height:251px;background: #fff;border-radius: 8px;"></div>
                </el-col>
                <el-col :span="8"
                    style="display: flex;flex-direction: column;justify-content: space-between;height:518px;width: 33%;padding: 0;margin-left: 16px;"
                    v-if="agentUserId">
                    <div id="main2" style="width: 100%;height:100%;background: #fff;border-radius: 8px;"></div>
                    <!-- <div id="main3" style="width: 100%;height:251px;background: #fff;border-radius: 8px;"></div> -->
                </el-col>
            </el-row>

            <!--用户学习数据-->
        <!-- <div style="display: flex;align-items: center;">
                <div @click="changeStateStudy(false)">全部</div>
                <div @click="changeStateStudy(true)">本月新增</div>
                </div> -->
            <div>
                <div style="display: flex;border-radius: 8px 8px 0 0;line-height: 34px;font-size: 14px;width: 224px;background:#fff;">
                <div v-for="(item, index) in statusList" :key="index" style="width: 112px;text-align: center;"
                    :class="ind == index ? 'currentTitle' : 'titlelayout'" @click="titleChange(index)">{{ item
                    }}
                </div>
            </div>
            </div>
            <div class="bottom">
                <div class="bordertitle">
                    <div>

                        <div style="color: #7E8887;">用户学习数据</div>
                    </div>
                    <div style="display: flex;align-items: center;font-weight: 400;">

                    </div>
                </div>
                <div class="bordertitle" style="color: #7E8887;font-size: 14px;">

                    <div style="display: flex;align-items: center;font-weight: 400;">
                        <div>搜索</div>
                        <el-input v-model="searchPhoneNum" placeholder="请输入手机号/用户名/门店"
                            style="width:200px;margin-left: 5px;">
                        </el-input>
                        <div style="flex-shrink: 0;margin-left: 10px;">等级</div>
                        <el-select v-model="levelId" placeholder="筛选" class="cinput ac" style="width: 100px;" clearable>
                            <el-option v-for="item in levelList" :key="item.levelId" :label="item.levelName"
                                :value="item.levelId">
                            </el-option>
                        </el-select>
                        <div style="display: flex;align-items: center;">
                            <div style="flex-shrink: 0;margin-left: 10px;">学习时长</div>
                            <el-input v-model="lowestStudyTimeMinute" placeholder="最短时长" style="width: 60px;">
                            </el-input>
                            <div>至</div>
                            <el-input v-model="highestStudyTimeMinute" placeholder="最长时长" style="width: 60px;">
                            </el-input>
                            <div>小时</div>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;font-weight: 400;">
                        <div style="flex-shrink: 0;">时间</div>
                        <el-date-picker v-model="timeScope2" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd" style="margin: 0 20px;">
                        </el-date-picker>
                        <el-button type="primary" @click="list">确定</el-button>
                        <el-button type="primary"  @click="exportExcel" v-if="agentUserId">导出</el-button>
                    </div>
                </div>
                <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData"
                    style="width: 100%; min-height: 350px" >

                    <el-table-column type="index" width="50"> </el-table-column>
                    <el-table-column prop="userName" label="用户名称" align="center">
                    </el-table-column>
                    <el-table-column prop="levelName" align="center" label="等级" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="storeName" align="center" label="门店" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="studyTimeMinutes" align="center" label="用户学习时长" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center" v-if="scope.row.studyMinutes">
                                {{ scope.row.studyMinutes | stime }}
                            </div>
                            <div style="display: flex; justify-content: center" v-if="!scope.row.studyMinutes">
                                0小时0分
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" align="center">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center">
                                <el-link type="primary" :underline="false" @click='details(scope.row)'>查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                ">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="pageIndex" :page-sizes="[10, 15, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>

                    <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData2"
                    style="width: 100%; min-height: 350px;display: none;" id="out-table">

                    <el-table-column type="index" width="50"> </el-table-column>
                    <el-table-column prop="userName" label="用户名称" align="center">
                    </el-table-column>
                    <el-table-column prop="levelName" align="center" label="等级" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="storeName" align="center" label="门店" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="studyTimeMinutes" align="center" label="用户学习时长" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center" v-if="scope.row.studyMinutes">
                                {{ scope.row.studyMinutes | stime }}
                            </div>
                            <div style="display: flex; justify-content: center" v-if="!scope.row.studyMinutes">
                                0小时0分
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" align="center">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center">
                                <el-link type="primary" :underline="false" @click='details(scope.row)'>查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                </div>
            </div>
        <!-- <div style="margin:0 auto; padding:20px 0;">
          <a  href=" " style="display:flex;text-decoration:none;height:20px;line-height:20px;align-items: center;justify-content: center;">
            <img src="../images/record.png" style="width:20px"/>
            <p style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;text-align:center">浙公网安备 33010202002041号 </p >
            </a>
          <p style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;text-align:center">浙ICP备2021037496号-1 </p >
                  </div> -->
        </div>
    </div>
</template>

<script>
import FileSaver from "file-saver";
import * as XLSX from 'xlsx'
import { Loading } from 'element-ui';
import { levelData } from '../utils/levelData'
import { getStatistic, storepageList, homebeauticianList, agentData, agentData2, agentTraineesPage, levelList, getCountHome, getIndexData } from '../apis/index'
export default {
    data() {
        return {
            ind:0,
            statusList: ['全部', '本月新增'],
            userRegisterStartTime: '',
            titleData: {
                inner: {},
                outViper: {},
                viper: {}
            },
            dataMain: {},
            map1listx: [],
            map1listy: [],
            map2List: [],
            map3List: [],
            searchPhoneNum: '',
            levelSortHighToLow: 0,
            levelSortHighToLow: 0,
            levelId: '',
            levelList: [],
            levelSortList: [
                {
                    id: 1,
                    name: '升序'
                },
                {
                    id: 2,
                    name: '降序'
                },
            ],
            levelSortId: '',
            timeList: [
                {
                    id: 1,
                    name: '升序'
                },
                {
                    id: 2,
                    name: '降序'
                },
            ],
            timeId: '',
            num: 0,
            datas: {},
            agentUserId: '',
            timeScope: [],
            timeScope2: [],
            lowestStudyTimeMinute: '',
            highestStudyTimeMinute: '',
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            storeId: '',
            storeList: [],
            brandList: [],
            brandId: 1,
            tableData: [

            ],
            questionCount: 0,
            contentVideoCount: 0,
            contentRichTextCount: 0,
            traineeCount: 0,
            traineeCount: 0,
            mentorCount: 0,
            tableData2:[]
        };
    },
    watch: {
        levelSortId(newData, oldData) {
            console.log(newData)
            if (newData == 1) {
                this.levelSortLowToHigh = 1
                this.levelSortHighToLow = 0
            } else if (newData == 2) {
                this.levelSortLowToHigh = 0
                this.levelSortHighToLow = 1
            } else {
                this.levelSortLowToHigh = ''
                this.levelSortHighToLow = ''
            }
            // this.list()
        },
        timeId(newData, oldData) {
            if (newData == 1) {
                this.studyTimeSortLowToHigh = 1
                this.studyTimeSortHighToLow = 0
            } else if (newData == 2) {
                this.studyTimeSortLowToHigh = 0
                this.studyTimeSortHighToLow = 1
            } else {
                this.studyTimeSortLowToHigh = ''
                this.studyTimeSortHighToLow = ''
            }
            // this.list()
        }
    },
    filters: {
        stime(value) {
            return parseInt(value / 60) + '小时' + value % 60 + '分'
        }
    },
    mounted() {
        this.agentUserId = this.$store.state.userId ? this.$store.state.userId : ''
        // this.init();
        // this.getMap().then(res => {
        //     this.init2();
        //     this.init3();
        // })
        this.getData().then(res => {
            this.getMap1()
            this.getMap3()
            this.getMap2()
        })
        // this.getAgentData().then(res => {
        //     this.getMap2()
        // })

    },
    computed: {
        //inner内部用户且不包含vip  outViper外部vip viper正常vip
        countAll() {//注册用户数
            return this.titleData.inner.count + this.titleData.outViper.count + this.titleData.viper.count
        },
        vipCount() {//开通会员用户数
            return this.titleData.outViper.count + this.titleData.viper.count
        },
        multiplePay() {//复购人数
            return this.titleData.multiBuy
        },
        multiJiaDunBuy() {//复购嘉顿人数
            return this.titleData.multiJiaDunBuy
        },
        multiDuoLaBuy() {//复购朵拉人数
            return this.titleData.multiDuoLaBuy
        },
        monthAddCount() {//本月新增人数
            return this.titleData.inner.countThisMonth + this.titleData.outViper.countThisMonth + this.titleData.viper.countThisMonth
        },
        studyTimeTotal() {//总学习时间
            return ((this.titleData.outViper.studyTime + this.titleData.viper.studyTime) / 60).toFixed(1)
        },
        monthVipStudyTime() {//当月新增会员学习时间
            return ((this.titleData.outViper.studyTimeThisMonthRegister + this.titleData.viper.studyTimeThisMonthRegister) / 60).toFixed(1)
        },
        monthVipStudyTimeAverage() {//学员月平均学习时长
            return ((this.titleData.outViper.studyTimeAverageMonth + this.titleData.viper.studyTimeAverageMonth) / 60).toFixed(1)
        },
        countStudy5Hour() {//学习5小时以上人数
            return this.titleData.outViper.countStudy5Hour + this.titleData.viper.countStudy5Hour
        }
    },
    created() {
        this.agentUserId = this.$store.state.userId ? this.$store.state.userId : ''
        // this.onclick()
        // this.brandList = this.$store.state.brandList
        // this.store()
        this.getCurrentTime()
        // this.getAgentData()
        // this.getStuentNum()
        this.list()
        this.levelList = levelData
        // this.leave()
        // this.getData()
    },
    methods: {
        exportExcel() {//导出表格
            this.list2().then(res=>{
                /* 从表生成工作簿对象 */
                let wb = XLSX.utils.table_to_book(document.querySelector('#out-table'))
                /* 获取二进制字符串作为输出 */
                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    bookSST: true,
                    type: 'array'
                })
                try {
                    FileSaver.saveAs(
                        //Blob 对象表示一个不可变、原始数据的类文件对象。
                        //Blob 表示的不一定是JavaScript原生格式的数据。
                        //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                        //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                        new Blob([wbout], { type: 'application/octet-stream' }),
                        //设置导出文件名称
                        '用户学习数据.xlsx'
                    )
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout)
                }
                return wbout
            })

        },
        titleChange(ind) {
            this.ind = ind
            if(ind==0){
                this.changeStateStudy(false)
            }else{
                this.changeStateStudy(true)
            }
        },
        changeStateStudy(value) {
            let date = new Date
            let year = date.getFullYear()
            let month = Number(date.getMonth() + 1) < 10 ? '0' + Number(date.getMonth() + 1) : Number(date.getMonth() + 1)
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
            let userRegisterStartTime = `${year}-${month}-01`
            this.pageIndex = 1
            this.pageSize = 10
            value ? this.userRegisterStartTime = userRegisterStartTime : this.userRegisterStartTime = ''
            this.list()
        },
        async getData() {//获取顶部八个数据以及绘图 inner内部用户且不包含vip  outViper外部vip viper正常vip
            const { data } = await getIndexData({ agentUserId: this.agentUserId })
            if (data.code == 200) {
                this.titleData = data.data

                let titleData = data.data

                let list = [{ name: `已开通人数 ${this.vipCount}人`, value: this.vipCount }, { name: `复购人数 ${this.multiplePay}人`, value: this.multiplePay }, { name: `未开通人数${this.countAll - this.vipCount}人`, value: this.countAll - this.vipCount }]
                this.map2List = list
                let map3List = []
                let map1listx = []
                let map1listy = []
                const yearData = Object.entries(titleData.outViper.monthMap).reduce((acc, [key, value]) =>
                    ({ ...acc, [key]: (acc[key] || 0) + value })
                    , { ...titleData.viper.monthMap });
                Object.keys(yearData).forEach((key) => {
                    map1listx.push(key.slice(4) + '月')
                    map1listy.push(yearData[key])
                })
                this.map1listx = map1listx
                this.map1listy = map1listy
                let innerBrandMap = titleData.inner.brandMap
                let outViper = titleData.outViper.brandMap
                let viper = titleData.viper.brandMap

                const merged = Object.entries(innerBrandMap).reduce((acc, [key, value]) =>
                    ({ ...acc, [key]: (acc[key] || 0) + value })
                    , { ...viper });
                const mergedTotal = Object.entries(merged).reduce((acc, [key, value]) =>
                    ({ ...acc, [key]: (acc[key] || 0) + value })
                    , { ...outViper });
                Object.keys(mergedTotal).forEach((key) => {
                    map3List.push({
                        name: key,
                        value: mergedTotal[key]
                    })
                })
                map3List.forEach(item => {
                    if (item.name == 1) {
                        item.name = `嘉顿肯妮 ${item.value}人`
                    } else if (item.name == 2) {
                        item.name = `瑞妮朵拉 ${item.value}人`
                    } else if (item.name == 3) {
                        item.name = `素颜+ ${item.value}人`
                    } else if (item.name == 10) {
                        item.name = `外部学员 ${item.value}人`
                    } else {
                        item.name = `测试数据 ${item.value}人`
                    }
                })
                this.map3List = map3List
            } else {
                this.$message.error(data.msg)
            }
        },
        async getHomeData() {//获取趋势图数据
            const params = this.agentUserId ? { agentUserId: this.agentUserId } : ''
            const { data } = await getCountHome(params)
            if (data.code == 200) {
                let res = data.data
                this.dataMain = data.data
                this.num = data.data.study5HourCount
                let list = [{ name: '已开通人数', value: data.data.vipCount }, { name: '未开通人数', value: data.data.count - data.data.vipCount }]
                this.map2List = list
                let buyVipCount = res.buyVipCount
                let brandVipCount = res.brandVipCount
                let map3List = []
                let map1listx = []
                let map1listy = []
                Object.keys(buyVipCount).forEach((key) => {
                    map1listx.push(key.slice(4) + '月')
                    map1listy.push(buyVipCount[key])
                })
                this.map1listx = map1listx
                this.map1listy = map1listy
                Object.keys(brandVipCount).forEach((key) => {
                    map3List.push({
                        name: key,
                        value: brandVipCount[key]
                    })
                })
                map3List.forEach(item => {
                    if (item.name == 1) {
                        item.name = '嘉顿肯妮'
                    } else if (item.name == 2) {
                        item.name = '瑞妮朵拉'
                    } else if (item.name == 3) {
                        item.name = '素颜+'
                    } else if (item.name == 10) {
                        item.name = '外部学员'
                    } else {
                        item.name = '测试数据'
                    }
                })
                this.map3List = map3List
            }
        },
        getMap1() {
            var myChart = this.$Echarts.init(document.getElementById('main1'));

            // 指定图表的配置项和数据
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                title: {
                    text: '购买会员数量趋势图',
                    left: 'left'
                },
                xAxis: {
                    type: 'category',
                    data: this.map1listx
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: this.map1listy,
                        type: 'line',
                        smooth: true
                    }
                ]
            };

            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);

        },
        getMap2() {
            var myChart = this.$Echarts.init(document.getElementById('main2'));

            // 指定图表的配置项和数据
            var option = {
                title: {
                    subtext: '开通会员,复购人数比例',
                    left: 'left'
                },
                tooltip: {
                    trigger: 'item'
                },
                color: ['#00E6C4', '#00E6C4', '#449EFF'],
                series: [
                    {
                        // name: 'Access From',
                        type: 'pie',
                        radius: '50%',
                        data: this.map2List,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);

        },
        getMap3() {
            if (this.agentUserId) return
            var myChart = this.$Echarts.init(document.getElementById('main3'));

            // 指定图表的配置项和数据
            var option = {
                title: {
                    subtext: '不同品牌会员人数占比',
                    left: 'left'
                },
                tooltip: {
                    trigger: 'item'
                },
                color: ['#00E6C4', '#449EFF', '#4D56FE', '#FFEE58'],
                series: [
                    {
                        // name: 'Access From',
                        type: 'pie',
                        radius: '50%',
                        data: this.map3List,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);

        },
        async leave() {//获取等级列表
            const { data } = await levelList({ roleId: 3 })
            if (data.code == 200) {

                this.levelList = data.data
            } else {
                this.$message.error(data.msg)
            }
        },
        search() {
            // this.getAgentData()
            // this.getStuentNum()
            this.list()

        },
        getCurrentTime() {//默认时间范围
            let date = new Date()
            let year = date.getFullYear()
            let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
            this.timeScope[0] = `${year}-01-01`
            this.timeScope[1] = `${year}-${month}-${day}`
            this.timeScope2[0] = `${year}-01-01`
            this.timeScope2[1] = `${year}-${month}-${day}`
            return `${year}-${month}-${day}`
        },
        async list() {
            const params = {
                agentUserId: this.agentUserId,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                // pageSize:999,
                startTime: this.timeScope2[0],
                endTime: this.timeScope2[1],
                lowestStudyTimeMinute: this.lowestStudyTimeMinute ? this.lowestStudyTimeMinute * 60 : '',
                highestStudyTimeMinute: this.highestStudyTimeMinute ? this.highestStudyTimeMinute * 60 : '',
                levelId: this.levelId,
                levelSortHighToLow: this.levelSortHighToLow,
                levelSortLowToHigh: this.levelSortLowToHigh,
                studyTimeSortHighToLow: this.studyTimeSortHighToLow,
                studyTimeSortLowToHigh: this.studyTimeSortLowToHigh,
                searchPhoneNum: this.searchPhoneNum,
                userRegisterStartTime: this.userRegisterStartTime
            }
            const { data } = await agentTraineesPage(params)
            if (data.code == 200) {

                let list = data.data.records
                this.tableData = list
                this.total = data.data.total
                let levelSortId = this.levelSortId
                let timeId = this.timeId
                // if(levelSortId==1){//等级
                //     list=list.sort(this.sortLevelTop('levelId'))
                // }else if(levelSortId==2){
                //     list=list.sort(this.sortLevelBottom('levelId'))
                // }
                // if(timeId==1){//学习时长
                //     list=list.sort(this.sortLevelTop('studyMinutes'))
                // }else if(timeId==2){
                //     list=list.sort(this.sortLevelBottom('studyMinutes'))
                // }
            }
        },
        async list2() {
            const params = {
                agentUserId: this.agentUserId,
                pageIndex: 1,
                // pageSize: this.pageSize,
                pageSize:999,
                startTime: this.timeScope2[0],
                endTime: this.timeScope2[1],
                lowestStudyTimeMinute: this.lowestStudyTimeMinute ? this.lowestStudyTimeMinute * 60 : '',
                highestStudyTimeMinute: this.highestStudyTimeMinute ? this.highestStudyTimeMinute * 60 : '',
                levelId: this.levelId,
                levelSortHighToLow: this.levelSortHighToLow,
                levelSortLowToHigh: this.levelSortLowToHigh,
                studyTimeSortHighToLow: this.studyTimeSortHighToLow,
                studyTimeSortLowToHigh: this.studyTimeSortLowToHigh,
                searchPhoneNum: this.searchPhoneNum,
                userRegisterStartTime: this.userRegisterStartTime
            }
            const { data } = await agentTraineesPage(params)
            if (data.code == 200) {

                let list = data.data.records
                this.tableData2 = list
                this.total = data.data.total
                let levelSortId = this.levelSortId
                let timeId = this.timeId
                // if(levelSortId==1){//等级
                //     list=list.sort(this.sortLevelTop('levelId'))
                // }else if(levelSortId==2){
                //     list=list.sort(this.sortLevelBottom('levelId'))
                // }
                // if(timeId==1){//学习时长
                //     list=list.sort(this.sortLevelTop('studyMinutes'))
                // }else if(timeId==2){
                //     list=list.sort(this.sortLevelBottom('studyMinutes'))
                // }
            }
        },
        sortLevelTop: function (prop) {//升序
            return function (obj1, obj2) {
                var val1 = obj1[prop]
                var val2 = obj2[prop]
                if (val1 < val2) {
                    return -1
                } else if (val1 > val2) {
                    return 1
                } else {
                    return 0
                }
            }
        },
        sortLevelBottom: function (prop) {//降序
            return function (obj1, obj2) {
                var val2 = obj1[prop]
                var val1 = obj2[prop]
                if (val1 < val2) {
                    return -1
                } else if (val1 > val2) {
                    return 1
                } else {
                    return 0
                }
            }
        },

        async getAgentData() {//获取用户以及会员数量
            const params = {
                agentUserId: this.agentUserId,
                endTime: this.timeScope[1],
                startTime: this.timeScope[0],
            }
            console.log(params)
            const { data } = await agentData(params)
            if (data.code == 200) {
                this.datas = data.data
                let list = [{ name: '已开通人数', value: data.data.vipCount }, { name: '未开通人数', value: data.data.count - data.data.vipCount }]
                this.map2List = list
            }
        },
        async getStuentNum() {//获取学习5小时用户数量
            const params = {
                lowestStudyTimeMinute: 300,
                endTime: this.timeScope[1],
                startTime: this.timeScope[0],
            }
            const { data } = await agentData2(params)
            if (data.code == 200) {
                this.num = data.data
            }
        },
        details(row) {//查看详情
            this.$router.push({ path: '/beauticiandetails2', query: { row: JSON.stringify(row) } })
        },
        handleSizeChange(val) {
            this.pageSize = val
            console.log(`每页 ${val} 条`);
            this.list()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            console.log(`当前页: ${val}`);
            this.list()
        },

        async store() {//获取门店列表
            const params = {
                pageIndex: 1,
                pageSize: 9999
            }
            const { data } = await storepageList(params)
            if (data.code == 200) {
                this.storeList = data.data.records
            } else {
                this.$message.error(data.msg)
            }
        },
        go1() {
            this.$store.commit('setIndex', 6)
            this.$router.push({ path: '/textIndex' })
        },
        go2() {
            this.$store.commit('setIndex', 3)
            this.$router.push({ path: '/people' })
        },
        go3() {
            this.$store.commit('setIndex', 2)
            this.$router.push({ path: '/exercises' })
        },
        go4() {
            this.$store.commit('setIndex', 1)
            this.$router.push({ path: '/content' })
        },
        async getMap() {//获取图表数据信息
            const { data } = await getStatistic()
            if (data.code == 200) {
                console.log(data.data)
                const { questionCount, contentVideoCount, contentRichTextCount, traineeCount, mentorCount, traineeVipCount, traineeMaxLevelCount } = data.data
                this.questionCount = questionCount
                this.contentVideoCount = contentVideoCount
                this.contentRichTextCount = contentRichTextCount
                this.traineeCount = traineeCount
                this.traineeCount = traineeCount
                this.mentorCount = mentorCount
                this.traineeVipCount = traineeVipCount
                this.traineeMaxLevelCount = traineeMaxLevelCount
            } else {
                this.$message.error(data.msg)
            }

        },
        init() {
            //2.初始化
            this.chart = this.$Echarts.init(this.$refs.chart);
            //3.配置数据
            var option = {
                // title: {
                //   text: 'Referer of a Website',
                //   subtext: 'Fake Data',
                //   left: 'center'
                // },
                tooltip: {
                    trigger: "item",
                },
                // legend: {
                //   orient: 'vertical',
                //   left: 'left'
                // },
                series: [
                    {
                        // name: 'Access From',
                        type: "pie",
                        // radius: "50%",
                        label: {
                            normal: {
                                position: "inner",
                                show: false,
                            },
                        },
                        data: [
                            { value: 1000, name: "已完成", itemStyle: { color: "#68DDD5" } },
                            { value: 100, name: "未完成", itemStyle: { color: "#FFE692" } },
                        ],
                        // emphasis: {
                        //   itemStyle: {
                        //     shadowBlur: 10,
                        //     shadowOffsetX: 0,
                        //     shadowColor: "rgba(0, 0, 0, 0.5)",
                        //   },
                        // },
                    },
                ],
            };

            // 4.传入数据
            this.chart.setOption(option);
        },
        // onclick(){
        //   let options={
        //     text:'拼命加载中'
        //   }
        //     let loadingInstance = Loading.service(options);
        //     setTimeout(() => {
        //       this.init2()
        //       loadingInstance.close();
        //     }, 1000);

        // },
        init2() {
            //2.初始化
            this.chart = this.$Echarts.init(this.$refs.chart2);
            //3.配置数据
            var option = {
                // title: {
                //   text: 'Referer of a Website',
                //   subtext: 'Fake Data',
                //   left: 'center'
                // },
                tooltip: {
                    trigger: "item",
                },
                // legend: {
                //   orient: 'vertical',
                //   left: 'left'
                // },
                series: [
                    {
                        // name: 'Access From',
                        type: "pie",
                        // radius: "50%",
                        label: {
                            normal: {
                                position: "inner",
                                show: false,
                            },
                        },
                        data: [
                            { value: this.questionCount, name: "题目数量", itemStyle: { color: "#ffe692" }, },
                            { value: this.contentVideoCount, name: "视频数量", itemStyle: { color: "#85FFF7" } },
                        ],
                        // emphasis: {
                        //   itemStyle: {
                        //     shadowBlur: 10,
                        //     shadowOffsetX: 0,
                        //     shadowColor: "rgba(0, 0, 0, 0.5)",
                        //   },
                        // },
                    },
                ],
            };
            // 4.传入数据
            this.chart.setOption(option);
        },
        init3() {
            //2.初始化
            this.chart = this.$Echarts.init(this.$refs.chart3);
            //3.配置数据
            var option = {
                // title: {
                //   text: 'Referer of a Website',
                //   subtext: 'Fake Data',
                //   left: 'center'
                // },
                tooltip: {
                    trigger: "item",
                },
                // legend: {
                //   orient: 'vertical',
                //   left: 'left'
                // },
                series: [
                    {
                        // name: 'Access From',
                        type: "pie",
                        // radius: "50%",
                        label: {
                            normal: {
                                position: "inner",
                                show: false,
                            },
                        },
                        data: [
                            { value: this.traineeCount, name: "学员数量", itemStyle: { color: "#FF8D9F" }, },
                            { value: this.traineeCount, name: "用户数量", itemStyle: { color: "#79DAFE" } },
                        ],
                        // emphasis: {
                        //   itemStyle: {
                        //     shadowBlur: 10,
                        //     shadowOffsetX: 0,
                        //     shadowColor: "rgba(0, 0, 0, 0.5)",
                        //   },
                        // },
                    },
                ],
            };
            // 4.传入数据
            this.chart.setOption(option);
        },
    },
};
</script>

<style lang='less' scoped>
/deep/.titlelayout {
    display: flex;
    border-radius: 8px 8px 0 0;
    background: #DEE8E6;
    height: 34px;
    line-height: 34px;
    text-align: center;
    justify-content: center;
    color: #62807C;
}

/deep/.currentTitle {
    background: #fff;
    border-radius: 8px 8px 0 0;
    height: 34px;
    line-height: 34px;
    color: #00786B;
    font-weight: bold;
}

.main {
    display: flex;
    justify-content: space-between;

    img {
        width: 290px;
    }
}

.item {
    display: flex;
    align-items: center;
    // width: 290px;
    width: 24%;
    height: 143px;
    border-radius: 20px;
    justify-content: space-evenly;
    color: white;

    img {
        width: 88px;
        height: 88px;
    }

    div {
        display: flex;
    }
}

.backwriting {
    background: #fe7e92;
}

.backteacher {
    background: #7d95ff;
}

.backtopic {
    background: #4eded4;
}

.backlearning {
    background: #57d1fe;
}

.fs {
    font-size: 19px;
    line-height: 60px;
}

.fl {
    font-size: 33px;
}

.dataimg {
    margin: 26px 0;
    display: flex;
    justify-content: space-between;
}

.left {
    // width: 60%;
    width: 764px;
    background: #fff;
    // height: 100%;
    height: 463px;
    display: flex;
    // align-items: center;
    flex-direction: column;
}

.left>div:last-child {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    // flex-direction: column;
    // height: 300;
}

.left>div:last-child>div {
    display: flex;
    font-size: 14px;
    color: #000;
    flex-direction: column;
}

.end {
    width: 18px;
    height: 18px;
    background: #ffe692;
    border-radius: 10px;
    margin-right: 10px;
}

.unfinisher {
    width: 18px;
    height: 18px;
    background: #FF8D9F;
    border-radius: 10px;
    margin-right: 10px;
}

.title {
    display: flex;
    margin: 18px;
    align-items: center;
}

.title>div:first-child {
    width: 9px;
    height: 22px;
    border-radius: 10px;
    background: #80dfff;
    margin-right: 10px;
}

.title>div:last-child {
    font-size: 18px;
    font-weight: bold;
    color: #000;
}

.lefttext>div:nth-child(2) {
    margin: 35px 0;
}

.lefttext {
    // align-items: center;
}

.bottom {
    border-radius:0 0 14px 14px;
    padding: 19px 18px;
    background: #fff;
}

.line {
    width: 9px;
    height: 22px;
    background: #72daff;
    border-radius: 6px;
}

.bordertitle {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    // font-weight: bold;
    margin-bottom: 14px;
}

.bordertitle>div:first-child {
    display: flex;
}

.bordertitle>div:first-child>div:first-child {
    margin-right: 11px;
}

.cinput /deep/.el-input__inner {
    height: 34px;
    line-height: 10px;
}

.ac /deep/.el-input__inner {
    border: none;
    background: #f7f7f7;
    // margin-right: 11px;
}

.el-row {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}
</style>
